import React, {FC} from "react";
import styles from "./Contact.module.scss"
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {useTranslation} from "react-i18next";

const Contact: FC = () => {

    // @ts-ignore
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div style={{height: '5px'}} id="contact"/>
            <h2 style={{fontWeight: 400}}>{t('contactMessage')}</h2>
                <div className={styles.bubblecontainer}>
                <a href="tel:491792933613" target="_blank" rel="noreferrer">
                    <div className={styles.bubbles}>
                        <PhoneIcon fontSize="large"/>
                    </div>
                </a>
                <a href="mailto:siza.zaby@gmail.com" target="_blank" rel="noreferrer">
                    <div className={styles.bubbles}>
                        <EmailIcon fontSize="large"/>
                    </div>
                </a>
                <a href="https://wa.me/491792933613?text=Hey%20I%20found%20your%20website%20and%20wanted%20to%20reach%20out"
                   target="_blank" title="Share on whatsapp" rel="noreferrer">
                    <div className={styles.bubbles}>
                        <WhatsAppIcon fontSize="large"/>
                    </div>
                </a>
                </div>
        </div>
    );
}

export default Contact;