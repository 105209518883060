import React, {FC} from "react";
import styles from "./About.module.scss"
import {useTranslation} from "react-i18next";


const About: FC = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div style={{height: '5px'}} id="about"/>
            <h2>{t('integrationAssistant')}</h2>
            <h2>{t('interpreter')}</h2>
            <h2>{t('entrepreneur')}</h2>
            <h2>{t('lecturer')}</h2>
        </div>
    );
};

export default About;