import React, {MouseEvent, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import styles from './Header.module.scss'

const pages = ['Home', 'About me', 'Contact'];

const Header = () => {
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const onClickAnchor = (e: MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
        e.preventDefault();
        handleCloseNavMenu();
        document?.getElementById(e.currentTarget.getAttribute('data-value') ?? '')?.scrollIntoView({behavior: 'smooth'})
    }

    return (
        <AppBar position="sticky" sx={{backgroundColor: '#D11922', zIndex: 1}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h4"
                        noWrap
                        component="div"
                        sx={{mr: 2, display: {xs: 'none', md: 'flex'}}}
                        className={styles.name}
                    >
                        Siza Zaby
                    </Typography>
                    <Typography
                        variant="h4"
                        noWrap
                        component="div"
                        sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}, justifyContent: 'center'}}
                        className={styles.name}
                    >
                        Siza Zaby
                    </Typography>
                    <Box sx={{flexGrow: 0, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >
                            {pages.map((page) => {
                                const lowercase = page.toLowerCase();
                                const link = lowercase.includes(" ") ? lowercase.split(" ")[0] : lowercase;
                                return (
                                    <MenuItem key={page}>
                                        <div data-value={link} onClick={onClickAnchor}>
                                            <Typography textAlign="center">{page}</Typography>
                                        </div>
                                    </MenuItem>
                                )
                            })}
                        </Menu>
                    </Box>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}, justifyContent: 'flex-end'}}>
                        {pages.map((page) => {
                            const lowercase = page.toLowerCase();
                            const link = lowercase.includes(" ") ? lowercase.split(" ")[0] : lowercase;
                            return (
                                <Button
                                    key={page}
                                    onClick={onClickAnchor}
                                    sx={{my: 2, color: 'white', display: 'block'}}
                                    className={styles.button}
                                    data-value={link}
                                >
                                    {page}
                                </Button>
                            )
                        })}
                    </Box>

                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default Header;