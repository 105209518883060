import React, {FC} from "react";
import {Paper, ToggleButton, ToggleButtonGroup} from "@mui/material";
import styles from "./Home.module.scss"
import {useTranslation} from "react-i18next";

const Home: FC = () => {

    // @ts-ignore
    const {t, i18n} = useTranslation();
    const [alignment, setAlignment] = React.useState('de');


    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        i18n.changeLanguage(newAlignment);
        setAlignment(newAlignment);
    };
    // TODO check active color

    const textClassName = alignment !== 'ar' ? styles.animation : '';
    const textBlockClassName = alignment === 'en' ? `${styles.textblock} ${styles.english}` : styles.textblock;

    return (
        <div className={styles.container}>
            <div style={{height: '5px'}} id="home"/>
            <Paper elevation={3} className={styles.paper}>
                <div className={styles.imgcontainer}>
                    <img src="profil.jpg" alt="Profilbild"/>
                </div>
                <div className={textBlockClassName}>
                    <div className={`${styles.text} ${textClassName}`}>
                        <h1 style={{fontWeight: 400}}>{t('welcome')}</h1>
                    </div>
                </div>
            </Paper>
            <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleChange}
                sx={{backgroundColor: '#D11922'}}
            >
                <ToggleButton sx={{color: '#FFFFFF'}} value="de">Deutsch</ToggleButton>
                <ToggleButton sx={{color: '#FFFFFF'}} value="en">English</ToggleButton>
                <ToggleButton sx={{color: '#FFFFFF'}} value="ar">عربي</ToggleButton>
            </ToggleButtonGroup>
        </div>
    );

};

export default Home;