import React, {useEffect} from 'react'
import './main.css';
import Header from "./header/Header";
import Home from "./home/Home";
import Contact from "./contact/Contact";
import {Divider} from "@mui/material";
import styles from './Style.module.scss'
import About from "./about/About";
import {I18nextProvider} from "react-i18next";
import i18n from './i18n/i18n';



const App: React.FC = () => {

    useEffect(() => {
        document.getElementsByTagName('body')[0].scroll(0,1);
    }, [])

    return (

        <I18nextProvider i18n={i18n}>
            <Header/>
            <div className={styles.wrapper}>
                <Home/>
                <Divider/>
                <About/>
                <Divider/>
                <Contact/>
            </div>
        </I18nextProvider>
    );
}

export default App;